<template>
    <div class="bg-[#FBFBFD] content-grid--full">
        <div class="bg-[#21303F] my-8 p-12 px-18 max-w-7xl mx-auto rounded-2xl">
            <span class="text-white font-medium text-2xl text-center w-full flex gap-2 justify-center lg:justify-start">
                <span>Recognized by
                    <img src="/assets/landing/gartner-logo.svg" alt="Gartner logo" class="inline mb-1" /></span>
            </span>
            <div
                class="flex flex-col lg:flex-row gap-8 mt-8 lg:justify-around items-center lg:items-stretch text-white">
                <div class="max-w-sm flex gap-4 justify-center grow-0">
                    <div class="h-full">
                        <div class="w-10 h-10">
                            <img src="/assets/landing/icon-connection-gray.svg" alt=""
                                class="w-full h-full object-fill block" />
                        </div>
                    </div>
                    <div class="flex flex-col">
                        <span class="font-sans font-light text-xl">Gartner's Emerging Market Quadrant for Generative
                            AI
                            Technologies</span>
                        <!-- <p class="text-pretty mt-2">Emerging Market Quadrant for Generative AI Technologies</p> -->
                        <PathwayButton class="lg:mt-auto mt-4" type="light" href="/blog/gartner-gen-ai-engineering">
                            Read More</PathwayButton>
                    </div>
                </div>
                <div class="max-w-sm flex gap-4 justify-center grow-0">
                    <div class="h-full">
                        <div class="w-10 h-10">
                            <img src="/assets/landing/icon-connection-gray.svg" alt=""
                                class="w-full h-full object-fill block" />
                        </div>
                    </div>
                    <div class="flex flex-col">
                        <span class="font-sans font-light text-xl">Gartner's Market Guide for Event Stream
                            Processing</span>
                        <!-- <p class="text-pretty mt-2">Market Guide for Event Stream Processing</p> -->
                        <PathwayButton class="lg:mt-auto mt-4" type="light"
                            href="/blog/market-guide-event-stream-processing/#pathway-is-featured-in-gartners-market-guide-for-event-stream-processing">
                            Read
                            More</PathwayButton>
                    </div>
                </div>
                <div class="max-w-sm flex gap-4 justify-center grow-0">
                    <div class="h-full">
                        <div class="w-10 h-10">
                            <img src="/assets/landing/icon-connection-gray.svg" alt=""
                                class="w-full h-full object-fill block" />
                        </div>
                    </div>
                    <div class="flex flex-col">
                        <span class="font-sans font-light text-xl">Gartner's Market Guide for Data Analytics and
                            Intelligence
                            Platforms in Supply Chain</span>
                        <!-- <p class="text-balance mt-2">Market Guide for Data Analytics and Intelligence Platforms in
                            Supply
                            Chain</p> -->
                        <PathwayButton class="mt-8" type="light" href="/news/gartner-market-guide-supply-chain">
                            Read
                            More</PathwayButton>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>
